<template>
  <div class="sandbox">
    <div class="left">
      <a-select
        v-model="form.select"
        style="width: 100%"
        @change="leftSelectBtn(form.select)"
      >
        <a-select-option
          v-for="item in arrayObj.leftSelect"
          :key="item.id"
          :value="item.title"
        >
          {{ item.title }}
        </a-select-option>
      </a-select>
      <a-card
        v-for="(item, index) in docModuleList"
        :key="index"
        :title="item.module"
        style="width: 100%; margin-top: 20px"
      >
        <p
          class="ff-list"
          :class="{ active: e.summary == leftActive }"
          v-for="(e, i) in item.docItems"
          :key="i"
          @click="selectButton(e, i)"
        >
          {{ e.summary }}{{ e.version }}
        </p>
      </a-card>
    </div>
    <div class="right">
        <div
          class="ff-content"
        >
          <h2>
            <a-icon type="bars" style="margin-right: 10px" /><span>{{
              docItems.summary
            }}</span>
          </h2>
          <div class="xian"></div>
          <div class="ff-url" style="margin-bottom: 16px;">
            <div class="url-name">接口名</div>
            <div class="url-url">
              <a-tag color="green" v-for="item in docItems.httpMethodList" :key="item">
                {{ item }}
              </a-tag>
              {{docItems.name}}
            </div>
            <div class="url-version"></div>
          </div>         
          <div class="xian-title"><span>接口描述</span></div>
          <p style="margin: 10px 0">{{ docItems.summary }}</p>
          <div class="xian-title"><span>参数配置</span></div>
          <a-table
            :columns="columns"
            :data-source="docItems.parameterConfiguration"
            bordered
            :pagination="false"
          >
            <template slot="value" slot-scope="text, record">
              <a-input placeholder="" v-model="record.value" />
            </template>
          </a-table>
          <div class="ff-block">
            <div class="xian-title"><span>请求参数</span></div>
            <a-table
              :columns="columnsRequest"
              :data-source="docItems.requestParameters"
              bordered
              :pagination="false"
            >
              <template slot="value" slot-scope="text, record">
                <a-input placeholder="" v-model="record.value" />
              </template>
            </a-table>
          </div>
          <div class="xian-title"><span>请求方法</span></div>
          <a-select v-model="form.method">
            <a-select-option
              v-for="(docItems, index) in docItems.httpMethodList"
              :key="index"
              :value="docItems"
            >
              {{ docItems }}
            </a-select-option>
          </a-select>
          <div class="ff-set">
            <a-button type="primary" @click="sendRequest(docItems)"> 发送请求 </a-button>
          </div>
          <a-tabs default-active-key="1" style="padding-bottom: 100px">
            <a-tab-pane key="1" tab="请求信息">
              {{this.params}}
            </a-tab-pane>
            <a-tab-pane key="2" tab="响应信息" force-render>
              {{form.message}}
            </a-tab-pane>
          </a-tabs>          
      </div>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    title: "参数",
    dataIndex: "name",
  },
  {
    title: "值",
    dataIndex: "value",
    scopedSlots: { customRender: "value" },
  },
];

const columnsRequest = [
  {
    title: "参数",
    dataIndex: "name",
  },
  {
    title: "值",
    dataIndex: "value",
    scopedSlots: { customRender: "value" },
  },
  {
    title: "描述",
    dataIndex: "description",
  },
];

import { getDocBaseInfoApi, docinfoOrderApi, testApi } from "@/serve/Interface";
export default {
  data() {
    return {
      form: {
        select: "",
        method: '',
        message: ''
      },
      params: {},
      docModuleList: [],
      docItems: {},
      arrayObj: {
        leftSelect: [],
      },
      parameterConfiguration: [
        { key: 0, name: "appId", value: "" },
        { key: 1, name: "privateKey", value: "" },
        { key: 2, name: "appAuthToken", value: "" },
      ],
      columns,
      columnsRequest,
      leftActive: 0,
      rightActive: 0,
    };
  },
  mounted() {
    getDocBaseInfoApi().then((res) => {
      this.arrayObj.leftSelect = res.docInfoList || [];
      this.form.select = this.arrayObj.leftSelect[0].title;
      this.getDocinfoOrder(this.form.select);
    });
  },
  methods: {
    leftSelectBtn(title) {
      this.getDocinfoOrder(title);
    },
    getDocinfoOrder(name) {
      docinfoOrderApi(name).then((res) => {
        this.docModuleList = res.docModuleList; 
        this.docModuleList.forEach((res, index) => {
          res.docItems.length && res.docItems.forEach(r=>{
            r.parameterConfiguration = this.parameterConfiguration
          });
          if (index == 0) {
            this.docItems    = res.docItems.length && res.docItems[0]
            this.leftActive  = res.docItems.length && res.docItems[0].summary
            this.form.method = res.docItems.length && res.docItems[0].httpMethodList[0]
            this.docItems.requestParameters.forEach((res, index)=>{
              res.key = index
            })
          }
        });
      });
    },
    selectButton(e, i) {
      this.leftActive = e.summary
      this.docItems   = e
      window.scrollTo(0, 0)
      this.docItems.requestParameters.forEach((res, index)=>{
        res.key = index
      })      
    },
    sendRequest (item) {
      this.params = {}
      item.parameterConfiguration.forEach(res=>{
        this.params[res.name] = res.value
      });
      this.params.method      = item.name
      this.params.version     = item.version
      this.params.httpMethod  = this.form.method
      this.params.bizContent  = {}
      item.requestParameters.forEach((res, index)=>{
        this.params.bizContent[res.name] = res.value
      });
      this.params.bizContent = JSON.stringify(this.params.bizContent)
      testApi(this.params).then(res=>{
        this.form.message = res.message
      }).catch(res=>{})
    }
  },
};
</script>
<style lang="scss">
.sandbox {
  width: 1200px;
  height: calc(100% - 60px);
  margin: 0 auto;
  display: flex;
  .left {
    width: 240px;
    padding-top: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    .ff-list {
      cursor: pointer;
      &.active {
        color: #1890ff;
      }
    }
  }
  .right {
    width: calc(100% - 240px);
    border-left: 1px solid #eee;
    padding-top: 20px;
    padding-left: 20px;
    .xian {
      width: 100%;
      height: 1px;
      background: #eee;
      margin: 20px 0;
    }
    .xian-title {
      position: relative;
      font-size: 20px;
      margin: 20px 0;
      span {
        position: relative;
        z-index: 2;
        background: #fff;
        padding: 0 5px;
        margin: 0 20px;
      }
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: #eee;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
  .ff-set {
    margin: 20px 0;
  }
}
</style>
